import React from "react"
import { Row, Col, Button } from "reactstrap"
import Image from "../image"

const scheme = [
  "#007bff",
  "#105162",
  "#5A93A2",
  "#37C0DF",
  "#17a2b8",
  "#019adf",
  "#007bff",
  "#3395FF",
  "#0056B2",
  "#17a2b8",
  "#dc3545",
  "#00c1f4",
  "#009AC3",
  "#00C2ED",
]

function LeadsHeader({ title, scrollDown, scrollandCollapse }) {
  const [color, setColor] = React.useState()
  const [count, setCount] = React.useState(0)

  const handleScroll = () => {
    scrollDown.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
    // scrollandCollapse(true)
  }

  const switchColor = () => {
    setColor(scheme[count])
    if (count < scheme.length) {
      setCount(count + 1)
    } else {
      setCount(0)
    }
  }

  return (
    <div className="landing-container">
      <Row className="px-xs-2 px-md-5">
        <Col xs={12} lg={7} className="d-flex align-items-end mt-2">
          <h1 className="font-weight-bolder handling-heading d-none d-lg-block">
            {title}
          </h1>
          <h3 className="font-weight-bolder handling-heading d-lg-none text-center w-full">
            {title}
          </h3>
        </Col>
        <Col xs={12} lg={7}>
          <div className="arrow d-none d-lg-block">
            <h4 className="text-color-light">FILL OUT A QUICK 2 MINUTE FORM</h4>
          </div>
        </Col>
        <Col xs={12} lg={4} className="my-1 my-lg-5">
          <div id="bzWidgetContainer" />
        </Col>
        <Col xs={12} lg={7}>
          <div className="d-flex flex-column align-items-center my-2">
            <div className="p-2 mt-3 arrow-up d-lg-none">
              <h6 className="text-color-dark text-center m-0">
                FILL OUT A QUICK 2 MINUTE FORM
              </h6>
            </div>
          </div>
          <div className="mb-3 d-none d-lg-block">
            <button
              className="scroll-link font-weight-bold text-color-secondary btn"
              onClick={handleScroll}
            >
              Learn more
            </button>
          </div>
        </Col>

        <Col xs={12} lg={7}>
          <Row>
            <Col xs={11} className="d-flex align-items-center">
              <Image src="book.png" style={{ width: 45 }} />
              <h5 className="ml-3">
                Compare Multiple Rates, so you know you’re getting the best
                deal.
              </h5>
            </Col>
            <Col xs={11} className="pt-3 d-flex align-items-center">
              <Image src="document.png" style={{ width: 45 }} />
              <h5 className="ml-3">
                Compare quotes on essential services and equipment for your home
                or business.
              </h5>
            </Col>
          </Row>
          <Row className="d-none d-lg-block">
            <Col xs={12} md={11} lg={12} className="d-flex justify-content-end">
              <Image src="guide-arrow.png" style={{ width: 135 }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default LeadsHeader
