import React from "react"
import { Row, Col } from "reactstrap"
import Image from "../image"

function Works() {
  return (
    <Row className="background-light-grey">
      <Col xs={1} className="hidden-xs" />
      <Col xs={12} md={10} className="p-2 p-md-5">
        <h1 className="text-color-secondary m-3 d-none d-lg-block">HOW IT WORKS</h1>
        <h3 className="text-color-secondary m-3 d-lg-none text-center">HOW IT WORKS</h3>
        <div className="d-flex flex-wrap mt-5">
          <Col xs={12} md={4} className="d-flex flex-column w-25">
            <Image src="icon-02.png" style={{ width: 78 }} className="d-none d-lg-block" />
            <Image src="icon-02.png" style={{ width: 78, margin: '0 auto' }} className="d-lg-none" />
            <h5 className="mt-3 text-md-left text-center">Research Your Purchase</h5>
            <p className="text-left">
              By reading our buyer's advice, you can research your purchase.
              Our comprehensive buying price will educate you about the purchase.
            </p>
          </Col>
          <Col xs={12} md={4} className="d-flex flex-column w-25">
            <Image src="icon-03.png" style={{ width: 65 }} className="d-none d-lg-block" />
            <Image src="icon-03.png" style={{ width: 65, margin: '0 auto' }} className="d-lg-none" />
            <h5 className="mt-3 text-md-left text-center">Get Price Quotes</h5>
            <p className="text-left">
              By filling the above form, you can get a quick price quote.
              We help you shop from best vendors by letting you compare prices from
              professionals.
            </p>
          </Col>
          <Col xs={12} md={4} className="d-flex flex-column w-25">
            <Image src="icon-01.png" style={{ width: 100 }} className="d-none d-lg-block" />
            <Image src="icon-01.png" style={{ width: 100, margin: '0 auto' }} className="d-lg-none" />
            <h5 className="mt-3 text-md-left text-center">Save Time And Money</h5>
            <p className="text-left">
              Eventually, you will be saving a lot of time in making your best purchase.
              This let you connect to best vendors real quick, and all by staying home.
            </p>
          </Col>
        </div>
      </Col>
      <Col xs={1} className="hidden-xs" />
    </Row>
  )
}

export default Works
