import React from "react"
import { Row, Col } from "reactstrap"
import Image from "../image"

function Accordian({
  articleHeading,
  html,
  scrollCollapse,
  scrollandCollapse,
}) {
  const [collapse, setCollapse] = React.useState(false)

  const toggleAccordian = () => {
    setCollapse(!collapse)
    scrollandCollapse(false)
  }

  return (
    <Row>
      <Col xs={12} className="p-0">
        <div
          className="works-collapse-panel"
          onClick={toggleAccordian}
          onKeyDown={toggleAccordian}
          role="button"
          tabIndex="0"
        >
          <div className="d-flex align-items-center">
            <span>
              <Image
                src={
                  collapse || scrollCollapse ? "up-arrow.png" : "down-arrow.png"
                }
                style={{ width: 25 }}
              />
            </span>
            <h3 className="ml-3 text-heading">{articleHeading}</h3>
          </div>
        </div>
        <div
          className={`p-4 background-light-grey ${
            collapse || scrollCollapse ? "d-block" : "d-none"
          }`}
        >
          <div
            className="container px-md-5"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </Col>
    </Row>
  )
}

export default Accordian
